import * as React from 'react'
import styled from 'styled-components'
import { navy, grayText, lightBlue } from 'shared/colors'
import { DottedSection } from '../index'
import { BulletDiv, PricingButton } from '../../data'
import { BulletList } from 'views/components'
import { mobile } from 'shared/media-queries'

interface FeaturesProps {
	data: any // TODO
}

const Container = styled.div`
	box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.1);
	border: solid 2px ${lightBlue};
	background-color: white;
	width: 100%;
	height: 100%;
	text-align: center;
	padding: 25px 0;
`

const Title = styled.div`
	font-size: 24px;
	font-weight: bold;
	color: ${navy};
`

const Description = styled.div`
	font-size: 24px;
	font-weight: 300;
	color: ${grayText};
`

const Spacer = styled.div`
	height: 25px;
`

const Features: React.FC<FeaturesProps> = ({ data }) => {
	const { title, description, features, link } = data
	return (
		<Container>
			<Title>{title}</Title>
			{link}
			<Description>{description}</Description>
			<Spacer />
			<DottedSection title="TOP FEATURES" />
			<BulletDiv>
				<BulletList
					dotColor="black"
					additionalLiCss={{
						[mobile]: {
							'font-size': '20px',
							margin: '0 auto',
							padding: '10px 0',
						},
					}}
					items={features}
				/>
			</BulletDiv>
			<PricingButton />
		</Container>
	)
}

export default Features
