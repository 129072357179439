import * as React from 'react'
import { lightBlue } from 'shared/colors'
import { H2, FAQTable } from 'views/components'
import faqs from './faqs'
import styled from 'styled-components'

const Spacer = styled.div`
	height: 40px;
`

const FAQsSection = () => (
	<div>
		<H2 textAlign="center">FAQs</H2>
		<Spacer />
		<FAQTable faqs={faqs} borderColor={lightBlue} numbered />
	</div>
)

export default FAQsSection
