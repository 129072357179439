import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

const TomImage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				fileName: file(
					relativePath: { eq: "bc-pro/pricing/quotes/img/tom@3x.png" }
				) {
					childImageSharp {
						fixed(height: 100, quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<GatsbyImage fixed={data.fileName.childImageSharp.fixed} alt="Tom Image" />
	)
}

export default TomImage
