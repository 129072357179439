import * as React from 'react'
import styled from 'styled-components'
import { navy } from 'shared/colors'
import { tablet, laptop, mobile } from 'shared/media-queries'

// prettier-ignore
interface ContainerProps {
  hasBorder?: boolean;
  hideDescription?: boolean;
  children?: any;
}

// prettier-ignore
interface ContainerStyleProps {
  hideDescription?: boolean;
  hasBorder?: boolean;
}

const Container = styled.div`
	font-size: 20px;
	font-weight: bold;
	flex: 1 1 26%;
	max-width: 308px;
	color: ${navy};
	padding-left: 45px;
	padding-top: 15px;
	padding-bottom: 15px;
	border: ${(props: ContainerStyleProps) =>
		props.hasBorder ? 'solid 1px #f1f1f1' : 'none'};
	min-height: 60px;
	${tablet} {
		max-width: 100%;
		text-align: center;
		padding-left: 0;
		display: ${props => (props.hideDescription ? 'none' : 'initial')};
	}
	${mobile} {
		font-size: 18px;
	}
`

const Content = styled.div`
	max-width: 233px;
	${laptop} {
		max-width: 165px;
	}
	${tablet} {
		max-width: 100%;
		margin: 0 auto;
	}
	${mobile} {
		padding: 0 14px;
	}
`

const SectionDescription: React.FC<ContainerProps> = ({
	children,
	hasBorder,
	hideDescription,
}) => {
	return (
		<Container hasBorder={hasBorder} hideDescription={hideDescription}>
			<Content>{children}</Content>
		</Container>
	)
}

export default SectionDescription
