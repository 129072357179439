import * as React from 'react'
import Layout from 'components/layout'
import {
	SectionSpacer,
	ContentWrapper,
	Footer,
	GrayBackground,
} from 'views/components'
import useScrollTo from 'hooks/use-scroll-to'
import ROICalculator from 'views/components/roi-calc'
import { defaultCalcState, ranges, inputs } from '../roi-calc-data'
import ResultsSection from '../roi-calc-data/results-section'
import { lightBlue } from 'shared/colors'
import FAQsSection from '../faqs'
import Quotes from '../quotes'
import withCountry from 'shared/get-country'
import PricingMatrix from '../pricing-matrix'
import { TitleDiv } from './shared'

const PlansAndPricing = ({ country }) => {
	const roiRef = React.useRef(null)

	useScrollTo({
		keysToRefs: {
			roi: roiRef,
		},
		offset: -130,
	})

	return (
		<Layout
			title="Plans & Pricing - BuildingConnected"
			description="Compare BuildingConnected plans to see what’s best for your business"
		>
			<GrayBackground>
				<ContentWrapper>
					<SectionSpacer />
					<PricingMatrix />
					<SectionSpacer />
				</ContentWrapper>
			</GrayBackground>
			<ContentWrapper>
				<SectionSpacer />
				<ROICalculator
					title={
						<TitleDiv>
							Time is money. See how much your company can save with
							BuildingConnected Pro.
						</TitleDiv>
					}
					forwardedRef={roiRef}
					defaultState={defaultCalcState}
					ranges={ranges}
					inputs={inputs}
					resultsSection={state => {
						// @ts-ignore
						// state is read as {} despite this never happening
						// causing a type lint error
						return <ResultsSection values={state} country={country} />
					}}
				/>
				<SectionSpacer />
			</ContentWrapper>
			<GrayBackground>
				<SectionSpacer />
				<Quotes />
				<SectionSpacer />
			</GrayBackground>
			<ContentWrapper>
				<SectionSpacer />
				<FAQsSection />
				<SectionSpacer />
			</ContentWrapper>
			<Footer
				wording="Make the switch to BuildingConnected Pro today."
				demoButtonWording="Get a demo and a quote"
				demoButtonPadding="55px !important"
				ctaColor={lightBlue}
			/>
		</Layout>
	)
}

export default withCountry(PlansAndPricing)
