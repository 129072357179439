import * as React from 'react'
import styled from 'styled-components'
import { tablet } from 'shared/media-queries'

const Container = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	${tablet} {
		flex-direction: column;
	}
`

const SectionRow: React.FC = ({ children }) => {
	return <Container>{children}</Container>
}

export default SectionRow
