import styled from 'styled-components'
import { navy, grayText, blue } from 'shared/colors'
import { tablet, laptop, mobile } from 'shared/media-queries'

export const RootContainer = styled.div`
	height: 222px;
	padding: 0 75px;
	background-color: ${navy};
	${tablet} {
		height: 249px;
		padding: 25px 10px;
	}
	${mobile} {
		height: 309px;
		padding: 20px 10px;
	}
`
export const ResultsItemContainer = styled.div`
	font-weight: 700;
	display: flex;
	align-items: center;
`
export const TooltipHeader = styled.div`
	font-size: 20px;
	font-weight: 700;
	color: ${navy};
`
export const TooltipContent = styled.div`
	font-size: 16px;
`
export const SmallSpacer = styled.div`
	height: 5px;
`
export const ResultsContent = styled.div`
	flex: 1 1 100%;
	display: flex;
	width: 100%;
	${laptop} {
		flex: 0 0 auto;
		justify-content: space-between;
	}
	${tablet} {
		width: 100%;
	}
	${mobile} {
		flex-direction: column-reverse;
	}
`
export const BasicFlexDiv = styled.div`
	flex: 1 1 0%;
`
export const TotalDiv = styled.div`
	max-width: 275px;
	${laptop} {
		max-width: 100%;
		flex: 0 0 50%;
	}
	${tablet} {
		flex: auto;
		margin: 0 auto;
		text-align: center;
	}
	${mobile} {
		text-align: left;
		margin: 0;
	}
`
export const ResultsContainer = styled.div`
	height: 100%;
	display: flex;
	color: white;
	align-items: center;
	${laptop} {
		flex-direction: column;
	}
	${mobile} {
		justify-content: flex-start;
		margin: 0;
	}
`
export const ResultsTextContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	padding: 30px 0px;
	${laptop} {
		padding-top: 30px;
		min-height: 98px;
		padding-bottom: 0px;
	}
	${tablet} {
		align-items: center;
		min-height: 50px;
		padding: 0;
	}
	${mobile} {
		text-align: left;
	}
`
export const ButtonContainer = styled.div`
	margin-top: 85px;
	flex: 1 1 375px;
	display: flex;
	${laptop} {
		flex-direction: row;
		margin-top: 30px;
		width: 100%;
		justify-content: space-between;
	}
	${tablet} {
		flex-direction: column-reverse;
		align-items: center;
	}
	${mobile} {
		width: 100%;
		align-items: flex-start;
	}
`
export const WageContainer = styled.div`
	flex: 1 1 0%;
	${tablet} {
		display: flex;
	}
	${mobile} {
		justify-content: flex-start;
	}
`
export const UpperWageContainer = styled.div`
	padding-bottom: 10px;
	${laptop} {
		display: none;
	}
`
export const LowerWageContainer = styled.div`
	display: none;
	${laptop} {
		margin-top: 25px;
		display: inherit;
		min-width: 280px;
	}
	${tablet} {
		margin-top: 10px;
	}
	${mobile} {
		width: 100%;
	}
`
export const HourlyWageTitle = styled.div`
	font-size: 16px;
	font-weight: bold;
`
export const VariableSpacer = styled.div`
	flex: 0 0 150px;
	${laptop} {
		display: none;
	}
	${tablet} {
		display: initial;
		flex: 0 0 30px;
	}
	${mobile} {
		flex: 0 0 10px;
	}
`
export const ResultsSpacer = styled.div`
	flex: 0 0 60px;
	${laptop} {
		display: none;
	}
`
export const ResultsLowerSpacer = styled.div`
	${laptop} {
		flex: 0 0 5px;
	}
	${tablet} {
		flex: 0 0 15px;
		order: 2;
	}
`
export const PotentialSavingsContainer = styled.div`
	font-size: 54px;
	font-weight: 700;
	${mobile} {
		font-size: 32px;
	}
`
export const PotentialGrowthTitle = styled.div`
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-top: -10px;
	${mobile} {
		font-size: 20px;
		font-weight: 600;
		line-height: 1.2;
		letter-spacing: 1.2px;
	}
`

export const Span = styled.span`
	position: relative;
	top: 2px;
`

export const Input = styled.input`
	font-variant-numeric: tabular-nums;
	max-width: 90px;
	height: 24px;
	font-size: 16px;
	padding-left: 5px;
	background: white;
	border-radius: 1px;
	font-weight: 300;
	text-align: left;
	border: none;
	color: ${grayText};
	${tablet} {
		margin-left: 15px;
	}
`

export const A = styled.a`
	color: ${blue};
	text-decoration: underline;
`
