import * as React from 'react'
import {
	Wording,
	WordingBreak,
} from 'views/components/roi-calc/input-item/shared'
import { Span } from './components'
import QuestionMarkTooltip from 'views/components/question-mark-tooltip'

const keys = {
	ESTIMATORS: 'estimators',
	HOURS_SPENT_FIND: 'hoursSpentFind',
	PROJECTS_ASSIGNED: 'projectsAssigned',
	HOURS_SPENT_UPDATING: 'hoursSpentUpdating',
	TOTAL_HOURS: 'totalHours',
}

export const ranges = {
	[keys.ESTIMATORS]: { min: 1, max: 200 },
	[keys.PROJECTS_ASSIGNED]: { min: 1, max: 99 },
	[keys.HOURS_SPENT_FIND]: { min: 0.5, max: 99 },
	[keys.HOURS_SPENT_UPDATING]: { min: 0.5, max: 99 },
	[keys.TOTAL_HOURS]: { min: 0.5, max: 99 },
}

export const defaultCalcState = {
	[keys.ESTIMATORS]: 5,
	[keys.PROJECTS_ASSIGNED]: 4,
	[keys.HOURS_SPENT_FIND]: 3,
	[keys.HOURS_SPENT_UPDATING]: 2,
	[keys.TOTAL_HOURS]: 1,
}

export const inputs = [
	{
		key: keys.ESTIMATORS,
		wording: (
			<Wording>
				Total estimators on <WordingBreak /> your team
			</Wording>
		),
		min: 1,
		max: 200,
		step: 1,
	},
	{
		key: keys.PROJECTS_ASSIGNED,
		wording: (
			<Wording>
				Projects assigned to an <WordingBreak /> estimator per month{' '}
				<Span>
					<QuestionMarkTooltip
						title={
							<div>
								On average, how many projects does each estimator work on in a
								month?
							</div>
						}
					/>
				</Span>
			</Wording>
		),
		min: 0.5,
		max: 99,
		step: 1,
	},
	{
		key: keys.HOURS_SPENT_FIND,
		wording: (
			<Wording>
				Hours spent finding <WordingBreak /> subcontractors for a project{' '}
				<Span>
					<QuestionMarkTooltip
						title={
							<div>
								On average, how many hours does it take to find all of the
								subcontractors you need to invite to bid on a job?
							</div>
						}
					/>
				</Span>
			</Wording>
		),
		min: 0.5,
		max: 99,
		step: 0.5,
	},
	{
		key: keys.HOURS_SPENT_UPDATING,
		wording: (
			<Wording>
				Hours spent updating <WordingBreak /> contact info per week{' '}
				<Span>
					<QuestionMarkTooltip
						title={
							<div>
								On average, how many hours does one estimator spend updating
								outdated subcontractor contact information?
							</div>
						}
					/>
				</Span>
			</Wording>
		),
		min: 0.5,
		max: 99,
		step: 0.5,
	},
	{
		key: keys.TOTAL_HOURS,
		wording: (
			<Wording>
				Total hours to set up a <WordingBreak /> project{' '}
				<Span>
					<QuestionMarkTooltip
						title={<div>How long does it take to set up one project?</div>}
					/>
				</Span>
			</Wording>
		),
		min: 0.5,
		max: 99,
		step: 0.5,
	},
]
