import * as React from 'react'
import { useState } from 'react'
import {
	TooltipHeader,
	SmallSpacer,
	TooltipContent,
	VariableSpacer,
	ResultsSpacer,
	ResultsLowerSpacer,
	PotentialSavingsContainer,
	PotentialGrowthTitle,
	WageContainer,
	HourlyWageTitle,
	RootContainer,
	ResultsContainer,
	ResultsTextContainer,
	UpperWageContainer,
	ResultsContent,
	TotalDiv,
	ButtonContainer,
	LowerWageContainer,
	Input,
	A,
} from './components'
import {
	WordingBreak,
	isBackspacingTheEndOfInput,
} from 'views/components/roi-calc/input-item/shared'
import {
	formatCurrency,
	formatCurrencyWithDecimals,
	stripIllegalChars,
	getNumbersFromKeyDownBackspaceWithEndingSymbol,
} from 'views/components/roi-calc/shared'
import { Button } from 'views/components'
import { lightBlue } from 'shared/colors'
import QuestionMarkTooltip from 'views/components/question-mark-tooltip'
import { showRoiReportModal } from 'components/modal-manager'
import { laptop, mobile } from 'shared/media-queries'
import { countryCodes, currencySymbols } from 'shared/get-country'

// prettier-ignore
export interface StateValues {
	estimators: number;
	hoursSpentFind: number;
	projectsAssigned: number;
	hoursSpentUpdating: number;
	totalHours: number;
}
// prettier-ignore
interface ResultsProps {
	values: StateValues;
	country: string;
}

// prettier-ignore
interface WageProps {
	country: string;
	wage: any;
	updateWage: (wage: any) => any;
}

const WageSection: React.FC<WageProps> = ({ wage, updateWage, country }) => {
	const [isEditing, toggleEdit] = useState(false)

	const currencySymbol = currencySymbols[country]

	return (
		<WageContainer>
			<HourlyWageTitle>Estimator Hourly Wage:</HourlyWageTitle>
			<Input
				onClick={() => {
					toggleEdit(true)
				}}
				value={
					isEditing
						? wage
						: `${currencySymbol}${formatCurrencyWithDecimals(wage)}/hr`
				}
				onChange={e => {
					const updatedWage = stripIllegalChars(e)
					updateWage(updatedWage)
				}}
				onKeyDown={e => {
					if (isBackspacingTheEndOfInput(e)) {
						e.preventDefault()
						const updatedWage = getNumbersFromKeyDownBackspaceWithEndingSymbol(
							e
						)
						updateWage(updatedWage)
					}
				}}
				onBlur={() => {
					toggleEdit(false)
				}}
				onKeyPress={event => {
					if (
						event.target instanceof HTMLInputElement &&
						event.key === 'Enter'
					) {
						event.target.blur()
					}
				}}
			/>
		</WageContainer>
	)
}

const defaultWage = country => {
	switch (country) {
		case countryCodes.uk:
			return 12.5
		case countryCodes.ie:
			return 12.5
		case countryCodes.nz:
			return 25
		default:
			return 30
	}
}

const ResultsSection: React.FC<ResultsProps> = ({ values, country }) => {
	const [wage, updateWage] = useState(defaultWage(country))
	const {
		estimators,
		hoursSpentFind,
		projectsAssigned,
		hoursSpentUpdating,
		totalHours,
	} = values

	const findingHours = estimators * (projectsAssigned * 12) * hoursSpentFind
	const totalEsimatorHours = totalHours * (projectsAssigned * 12) * estimators
	const hoursPerWeekUpdating = hoursSpentUpdating * 50 * estimators
	const totalHoursSaved =
		(findingHours + totalEsimatorHours + hoursPerWeekUpdating) * 0.875

	const potentialSavings = totalHoursSaved * wage
	// This number is about the max the UI can handle, not quite all sliders maxed
	const showPotentialGrowthEasterEgg = potentialSavings >= 999999999

	return (
		<RootContainer>
			<ResultsContainer>
				<ResultsTextContainer>
					<UpperWageContainer>
						<WageSection
							wage={wage}
							updateWage={updateWage}
							country={country}
						/>
					</UpperWageContainer>
					<ResultsContent>
						<TotalDiv>
							<PotentialSavingsContainer>
								{showPotentialGrowthEasterEgg
									? 'MANY'
									: totalHoursSaved.toLocaleString()}
							</PotentialSavingsContainer>
							<PotentialGrowthTitle>
								HRS/YR TIME SAVED{' '}
								<QuestionMarkTooltip
									title={
										<div>
											<TooltipHeader>How was this calculated?</TooltipHeader>
											<SmallSpacer />
											<TooltipContent>
												BuildingConnected Pro users report that
												BuildingConnected Pro cuts <WordingBreak /> the time it
												takes to manage a project by 87.5%.
											</TooltipContent>
										</div>
									}
									wide
									fillColor="white"
								/>
							</PotentialGrowthTitle>
						</TotalDiv>
						<VariableSpacer />
						<TotalDiv>
							<PotentialSavingsContainer>
								{showPotentialGrowthEasterEgg
									? 'A LOT'
									: formatCurrency(potentialSavings, country)}
							</PotentialSavingsContainer>
							<PotentialGrowthTitle>
								IN POTENTIAL SAVINGS{' '}
								<QuestionMarkTooltip
									title={
										<div>
											<TooltipHeader>How was this calculated?</TooltipHeader>
											<SmallSpacer />
											<TooltipContent>
												According to{' '}
												<A
													target="_blank"
													href="https://www.payscale.com/research/US/Job=Construction_Estimator/Salary#targetText=The%20average%20pay%20for%20a%20Construction%20Estimator%20is%20%2459%2C361%20per%20year"
												>
													Payscale
												</A>
												, the average construction <WordingBreak />
												estimator’s salary is $59,767 - or about $30 per hour.
											</TooltipContent>
										</div>
									}
									wide
									fillColor="white"
								/>
							</PotentialGrowthTitle>
						</TotalDiv>
						<ResultsSpacer />
					</ResultsContent>
					<ResultsLowerSpacer />
				</ResultsTextContainer>

				<ButtonContainer>
					<LowerWageContainer>
						<WageSection
							wage={wage}
							updateWage={updateWage}
							country={country}
						/>
					</LowerWageContainer>
					<Button
						width="100%"
						label="Get a copy of your ROI report"
						backgroundColor={lightBlue}
						onClick={() =>
							showRoiReportModal({
								wage,
								hrsSavedPerYear: totalHoursSaved,
								potentialSavings,
								...values,
							})
						}
						css={{
							'max-width': '375px',
							'max-height': '44px',
							[laptop]: {
								flex: '0 0 50%',
								'max-width': '50%',
								'margin-top': '25px',
							},
							[mobile]: { 'max-width': '100%' },
						}}
					/>
				</ButtonContainer>
			</ResultsContainer>
		</RootContainer>
	)
}

export default ResultsSection
