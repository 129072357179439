import { navy } from 'shared/colors'
import styled from 'styled-components'
import { laptop, tablet } from 'shared/media-queries'

export const TitleDiv = styled.div`
	font-size: 36px;
	font-weight: bold;
	color: ${navy};
	margin: 0 auto;
	max-width: 100%;
	${laptop} {
		max-width: 860px;
	}
	${tablet} {
		max-width: 647px;
	}
`
