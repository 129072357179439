import * as React from 'react'
import styled from 'styled-components'
import { proFeatures, proTitle, A } from './data'
import { mobile } from 'shared/media-queries'
import { Comparison, Features } from './components/mobile'

const Container = styled.div`
	display: none;
	${mobile} {
		display: inherit;
		text-align: center;
	}
`

const Spacer = styled.div`
	height: 20px;
`

const CtaContainer = styled.div`
	margin-top: 10px;
`

const MobileMatrix: React.FC = () => (
	<Container>
		<Spacer />
		<Features
			data={{
				title: proTitle,
				features: proFeatures,
				link: (
					<CtaContainer>
						<A href="/bc-pro">Learn More &raquo;</A>
					</CtaContainer>
				),
			}}
		/>
		<Spacer />
	</Container>
)

export default MobileMatrix
