export default [
	{
		question: `What is the price?`,
		answer:
			'Your subscription is priced according to the number of users and offices in your company. For a detailed quote tailored to your company, contact our sales team.',
	},
	{
		question: `We use other Autodesk products. Are discounts available?`,
		answer:
			'Discounts may be available for Autodesk customers, depending on what products you use. Contact us for more information.',
	},
	{
		question: `What is the standard subscription length?`,
		answer:
			'Our standard subscription length is a 3-year agreement, however subscriptions are available for 1 or 2 years as well. Contact us for details.',
	},
	{
		question: `Are there additional fees for training or implementation?`,
		answer:
			'Virtual training, implementation, and customer support are included at no additional cost as part of your subscription. If onsite support is required (which is rare), this can be added to your subscription for an additional charge.',
	},
]
