import * as React from 'react'
import styled from 'styled-components'
import {
	SectionDescription,
	SectionRow,
	SectionItem,
	SectionHeader,
} from './index'
import { tablet } from 'shared/media-queries'

const Container = styled.div`
	display: flex;
	flex-direction: column;
`

const TypesDiv = styled.div`
	display: flex;
	flex: 1 1 74%;
	${tablet} {
		display: flex;
	}
`

// prettier-ignore
interface SectionProps {
	hideDescription?: boolean;
	data: {
		title?: string;
		content: Array<{
			description: React.ReactNode;
			pro: React.ReactNode;
		}>
	}
}

const Section: React.FC<SectionProps> = ({ data, hideDescription }) => {
	const { title, content } = data
	return (
		<Container>
			{title && <SectionHeader title={title} />}
			{content.map(({ description, pro }) => (
				<SectionRow>
					<SectionDescription hasBorder hideDescription={hideDescription}>
						{' '}
						{description}
					</SectionDescription>
					<TypesDiv>
						<SectionItem> {pro}</SectionItem>
					</TypesDiv>
				</SectionRow>
			))}
		</Container>
	)
}

export default Section
