import * as React from 'react'
import styled from 'styled-components'
import { navy } from 'shared/colors'
import data from '../data'
import { SectionDescription } from './index'
import { tablet } from 'shared/media-queries'

const Container = styled.div`
	height: 60px;
	background-color: ${navy};
	display: flex;
`

const SectionItem = styled.div`
	flex: 1 1 37%;
	text-align: center;
	color: white;
	padding-top: 7px;
	${tablet} {
		flex: 1 1 50%;
	}
`

const Footer: React.FC = () => {
	const {
		footer: { content },
	} = data
	const { pro } = content[0]
	return (
		<Container>
			<SectionDescription hideDescription />
			<SectionItem> {pro}</SectionItem>
		</Container>
	)
}

export default Footer
