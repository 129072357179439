import * as React from 'react'
import styled from 'styled-components'

import H1 from 'components/h1'
import { SectionSpacer } from 'views/components'
import Matrix from './matrix'
import MobileMatrix from './mobile-matrix'
import { mobile, tablet } from 'shared/media-queries'

const Title = styled(H1)`
	text-align: center;
	margin: 0 auto;
	width: 777px;
	font-weight: bold;
	font-size: 54px;
	${tablet} {
		max-width: 636px;
	}
	${mobile} {
		font-size: 40px;
		text-align: center;
		width: 100%;
	}
`
const PricingMatrix: React.FC = () => (
	<div>
		<Title>Invest in the bid management platform that pays for itself</Title>
		<SectionSpacer />
		<Matrix />
		<MobileMatrix />
	</div>
)

export default PricingMatrix
