import * as React from 'react'
import withCountry, { countryCodes } from 'shared/get-country'

import { default as MainUS } from './main/us'
import { default as MainOther } from './main/other'

const options = {
	[countryCodes.us]: <MainUS />,
	[countryCodes.nz]: <MainOther />,
	[countryCodes.au]: <MainOther />,
	[countryCodes.uk]: <MainOther />,
	[countryCodes.ie]: <MainOther />,
}

const page = ({ country }) => (country ? options[country] : null)

export default withCountry(page)
