import * as React from 'react'
import { DottedSection, Section } from './index'

interface PlansProps {
	data: any // TODO
}

const PlansSection: React.FC<PlansProps> = ({ data }) => {
	return (
		<div>
			<Section hideDescription data={data.types} />
			<DottedSection title="TOP FEATURES" />
			<Section hideDescription data={data.plans} />
		</div>
	)
}

export default PlansSection
