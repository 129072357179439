import * as React from 'react'
import styled from 'styled-components'
import { mobile } from 'shared/media-queries'
import { grayText } from 'shared/colors'

const Container = styled.div`
	min-height: 60px;
	flex: 1 1 37%;
	border: solid 1px #f1f1f1;
	text-align: center;
	color: ${grayText};
	${mobile} {
		font-size: 18px;
		padding: 0 14px;
	}
`
const Content = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	height: 100%;
`

const SectionItem: React.FC = ({ children }) => {
	return (
		<Container>
			<Content>{children}</Content>
		</Container>
	)
}

export default SectionItem
