import * as React from 'react'
import styled from 'styled-components'
import { lightBlue } from 'shared/colors'
import { tablet } from 'shared/media-queries'

// prettier-ignore
interface SectionHeaderStyleProps {
	border?: string;
	backgroundColor: string;
}

interface SectionHeaderProps {
	title: string
}

const Container = styled.div`
	background-color: ${(props: SectionHeaderStyleProps) =>
		props.backgroundColor};
	border: ${(props: SectionHeaderStyleProps) => props.border};
	height: 60px;
	font-size: 20px;
	font-weight: 600;
	color: white;
	letter-spacing: 1.2;
	padding-left: 45px;
	align-items: center;
	display: flex;
	${tablet} {
		font-size: 18px;
		padding: 0;
		justify-content: center;
	}
`

const SectionHeader: React.FC<SectionHeaderProps> = ({ title }) => {
	return (
		<Container backgroundColor={lightBlue}>{title.toUpperCase()}</Container>
	)
}

export default SectionHeader
