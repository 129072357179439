import * as React from 'react'
import { Testimonials } from 'views/components'
import { DougImage, MikeImage, TomImage } from './img'

const Quotes = () => (
	<Testimonials
		header="What customers are saying about us."
		data={[
			{
				image: <TomImage />,
				quote:
					"Keeping subcontractor records up to date used to take time out of every day, every single week of the year. That time adds up. Now that's basically dropped to zero.",
				name: 'Tom Juntunen',
				title: 'Senior Cost Analyst,',
				company: 'Trubeck Construction',
			},
			{
				image: <DougImage />,
				quote:
					"In years past, communication alone could take days. Now with BuildingConnected Pro, it's literally a matter of minutes.",
				name: 'Doug Ballou',
				title: 'Project Executive and Preconstruction Manager,',
				company: 'NOVO Construction',
			},
			{
				image: <MikeImage />,
				quote:
					'Implementation of BuildingConnected supports our effort to increase efficiency for Turner, our contractors and our clients.',
				name: 'Mike Bruskin',
				title: 'Vice President, Director of Procurement,',
				company: 'Turner Construction',
			},
		]}
	/>
)

export default Quotes
