import React from 'react'
import styled from 'styled-components'
import { lightBlue } from 'shared/colors'
import { tablet, mobile } from 'shared/media-queries'

const Container = styled.div`
	font-size: 20px;
	color: ${lightBlue};
	font-weight: 600;
	letter-spacing: 1.2;
	height: 60px;
	border-bottom: dashed 1px ${lightBlue};
	border-top: dashed 1px ${lightBlue};
	padding-left: 45px;
	padding-top: 15px;
	${tablet} {
		font-size: 18px;
		text-align: center;
		padding-left: 0;
	}
	${mobile} {
		padding-top: 12px;
		height: 50px;
	}
`
interface DottedSectionProps {
	title: string
}

const DottedSection: React.FC<DottedSectionProps> = ({ title }) => {
	return <Container>{title}</Container>
}

export default DottedSection
