import * as React from 'react'
import styled from 'styled-components'
import { lightBlue } from 'shared/colors'
import data from './data'
import { PlansSection, Section, Footer } from './components'
import { mobile } from 'shared/media-queries'
import Sticky from 'react-sticky-el'

const Container = styled.div`
	box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.1);
	border: solid 2px ${lightBlue};
	background-color: white;
	${mobile} {
		display: none;
	}
`

const Matrix: React.FC = () => (
	<Container>
		<PlansSection data={data} />
		<Section data={data.team} />
		<div id="boundary">
			<Section data={data.project} />
			<Section data={data.bid} />
			<Section data={data.analytics} />
			<Section data={data.integrations} />
			<Sticky mode="bottom" boundaryElement="#boundary">
				<Footer />
			</Sticky>
		</div>
	</Container>
)

export default Matrix
