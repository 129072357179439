import * as React from 'react'
import CloseIcon from 'imgs/icons/close.svg'
import CheckIcon from 'imgs/icons/checkmark.svg'
import { lightBlue, navy } from 'shared/colors'
import QuestionMarkTooltip from 'views/components/question-mark-tooltip'
import { BulletList, Button } from 'views/components'
import styled from 'styled-components'
import { laptop, tablet } from 'shared/media-queries'
import { showRequestDemoModal } from 'components/modal-manager'

export const proFeatures = [
	'Publish projects anywhere',
	`Unlimited bid invitations and projects`,
	'Custom bid forms',
	'Bid leveling',
	'NDAs and electronic sealed bids',
	'Advanced analytics and reporting',
	'Vendor relationship management',
	'Calendar sync',
	'TradeTapp qualification sync',
]

export const proTitle = 'BuildingConnected Pro'

export const A = styled.a`
	font-size: 18px;
	line-height: 18px;
	text-align: center;
	color: ${lightBlue};
	padding-top: 15px;
`

export const BulletDiv = styled.div`
	text-align: left;
	padding: 30px;
	font-size: 20px;
	font-weight: 300;
`
const StandardBullets = styled.div`
	align-self: flex-start;
`
const PlanDiv = styled.div`
	font-size: 20px;
	font-weight: 600;
	padding-top: 45px;
	width: 308px;
	color: ${lightBlue};
	${laptop} {
		width: auto;
	}
`

const PlanTitle = styled.div`
	font-size: 24px;
	font-weight: bold;
	color: ${navy};
`

const PlanTypeDiv = styled.div`
	padding: 45px;
	${laptop} {
		padding: 45px 0px;
	}
`
const CTADiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 60px;
	justify-content: space-between;
	margin-top: 40px;
`

const FooterSection = styled.div`
	justify-content: center;
	display: flex;
	align-items: center;
`

const ContentDivWithTooltip = styled.div`
	display: flex;
	justify-content: center;
`
const DivWithTooltip = styled.div`
	display: flex;
	${tablet} {
		justify-content: center;
	}
`
const TooltipContainer = styled.div`
	padding-left: 5px;
	padding-top: 2px;
`

export const PricingButton: React.FC = () => (
	<Button
		onClick={showRequestDemoModal}
		label="Get pricing"
		css={{ width: '177px' }}
	/>
)

export default {
	types: {
		title: null,
		content: [
			{
				description: <PlanDiv>PLAN</PlanDiv>,
				pro: (
					<PlanTypeDiv>
						<PlanTitle>{proTitle}</PlanTitle>
						<CTADiv>
							<PricingButton />
							<A href="/bc-pro" color={lightBlue}>
								Learn More &raquo;
							</A>
						</CTADiv>
					</PlanTypeDiv>
				),
			},
		],
	},
	plans: {
		title: null,
		content: [
			{
				description: '',
				pro: (
					<BulletDiv>
						<BulletList dotColor="black" items={proFeatures} />
					</BulletDiv>
				),
			},
		],
	},
	team: {
		title: 'Team Management',
		content: [
			{
				description: 'User limit',
				pro: (
					<ContentDivWithTooltip>
						Tiered{' '}
						<TooltipContainer>
							<QuestionMarkTooltip
								title="User limit starts at 15, but we will work with you to meet your needs."
								fillColor="black"
							/>
						</TooltipContainer>
					</ContentDivWithTooltip>
				),
			},
		],
	},
	project: {
		title: 'Project Management',
		content: [
			{
				description: (
					<DivWithTooltip>
						Project publishing radius
						<TooltipContainer>
							<QuestionMarkTooltip
								title="How far a project is from the publishing office."
								fillColor="black"
							/>
						</TooltipContainer>
					</DivWithTooltip>
				),
				pro: 'Unlimited',
			},
			{
				description: 'Monthly project publishing limit',
				pro: 'Unlimited',
			},
			{
				description: 'Monthly invites limit',
				pro: 'Unlimited',
			},
			{
				description: (
					<DivWithTooltip>
						Project data retention
						<TooltipContainer>
							<QuestionMarkTooltip
								title="How long you can view and access data of all projects"
								fillColor="black"
							/>
						</TooltipContainer>
					</DivWithTooltip>
				),
				pro: 'Unlimited',
			},
		],
	},
	bid: {
		title: 'Bid Management',
		content: [
			{
				description: 'Custom bid forms',
				pro: <CheckIcon fill={lightBlue} width="23px" height="16px" />,
			},
			{
				description: (
					<DivWithTooltip>
						Bid leveling
						<TooltipContainer>
							<QuestionMarkTooltip
								title="Compare bids side-by-side to quickly find the best bid."
								fillColor="black"
							/>
						</TooltipContainer>
					</DivWithTooltip>
				),
				pro: <CheckIcon fill={lightBlue} width="23px" height="16px" />,
			},
			{
				description: 'View bidding history',
				pro: <CheckIcon fill={lightBlue} width="23px" height="16px" />,
			},
			{
				description: 'NDAs and electronic sealed bids',
				pro: <CheckIcon fill={lightBlue} width="23px" height="16px" />,
			},
			{
				description: 'Assign bid package lead',
				pro: <CheckIcon fill={lightBlue} width="23px" height="16px" />,
			},
			{
				description: 'Proposal summary sheet',
				pro: <CheckIcon fill={lightBlue} width="23px" height="16px" />,
			},
		],
	},
	analytics: {
		title: 'Analytics and reporting',
		content: [
			{
				description: 'Good faith effort reporting',
				pro: <CheckIcon fill={lightBlue} width="23px" height="16px" />,
			},
			{
				description: 'Project analytics',
				pro: <CheckIcon fill={lightBlue} width="23px" height="16px" />,
			},
			{
				description: 'Personalized data retention',
				pro: <CheckIcon fill={lightBlue} width="23px" height="16px" />,
			},
			{
				description: 'View all projects across company',
				pro: <CheckIcon fill={lightBlue} width="23px" height="16px" />,
			},
			{
				description: (
					<DivWithTooltip>
						View subcontractor activity
						<TooltipContainer>
							<QuestionMarkTooltip
								title="View all subs who bid and how often"
								fillColor="black"
							/>
						</TooltipContainer>
					</DivWithTooltip>
				),
				pro: <CheckIcon fill={lightBlue} width="23px" height="16px" />,
			},
		],
	},
	integrations: {
		title: 'integrations',
		content: [
			{
				description: 'Calendar sync: Outlook, Google and Apple',
				pro: <CheckIcon fill={lightBlue} width="23px" height="16px" />,
			},
			{
				description: 'TradeTapp integration',
				pro: <CheckIcon fill={lightBlue} width="23px" height="16px" />,
			},
			{
				description: 'PlanGrid integration',
				pro: <CheckIcon fill={lightBlue} width="23px" height="16px" />,
			},
			{
				description: 'Box file sync',
				pro: <CheckIcon fill={lightBlue} width="23px" height="16px" />,
			},
		],
	},
	footer: {
		title: null,
		content: [
			{
				description: '',
				pro: (
					<FooterSection>
						<PricingButton />
					</FooterSection>
				),
			},
		],
	},
}
